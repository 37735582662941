@use '../base/variables' as v;


html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    font-family: v.$fuente_principal;
    font-size: 1.6rem;
    line-height: 1.8;

    background-color: v.$grisOscuro;

}
p {
    color: v.$negro;
    font-size: 2rem;
}
.contenedor {
    width: 100%;
    margin-top: 4rem;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

// img,
// picture {
//     width: 100%;
//     display: block;
// }

h1, h2, h3 {
    font-family: v.$fuente_principal;
    text-align: center;
    padding: 1rem;
    
}

h1 {
    font-size: 4rem;
}
h2 {
    font-size: 4rem;
}
h3 {
    font-size: 3rem;
    color: v.$gris;
}
h4 {
    font-size: 2.6rem;
}

.tarjetas{
    text-align: center;
    .card{
        padding: 2rem;
        border-radius: 3rem 0 3rem 0;
        margin: 1rem;
        border: 3px solid v.$amarillo;
        color: v.$gris;
        background-color: v.$grisOscuro;
        box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.58);
    }
    .card-title{
        text-align: center;
        font-size:4rem;
        color: v.$amarillo;
    }
    .card-text{
        font-size: large;
        color: v.$silver ;
    }
}

.titulo-seccion {
    color: v.$amarillo;
    
  }