@use 'base/variables' as v;
@use 'base/mixins' as m;

#contenedor_galeria{
    background-color: v.$blanco;
    border-bottom: 3px solid v.$amarillo;
    .carousel-control-prev-icon,.carousel-control-next-icon {
        background-color: v.$grisOscuro;
        padding: 1.8rem;
        border-radius: 50%;
    }
    
    img{
        
        margin-left: auto;
        margin-right: auto;
        height: 40rem;
        box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.58);
       
    }
    @include m.tablet {
        height: 100vh;
        img{
            scale: .95;
 
            height:80rem;
            border-radius: 3rem 0 3rem 0;
        }  
        .titulo-seccion {
            font-size: 5rem;
          }
        
    }
    
    
}
