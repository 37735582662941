@use 'base/variables' as v;
@use 'base/mixins' as m;

#contenedor_footer{
    border-top: 3px solid v.$amarillo;
    text-align: justify;
    padding: 2rem;
    background: rgb(39,60,117);
background: linear-gradient(0deg, rgba(39,60,117,0) 10%, rgba(0,0,0,1) 100%);
    p{
        font-size: small;
        color: v.$silver;
    }
    a{
       display: flex;
       justify-content: center;
       align-items: center;
       color: v.$amarillo;
    }
    i{
        padding: 1rem;
    }

    @include m.tablet {
        p{
            text-align: center;
        }
    }

}