@use 'base/variables' as v;
@use 'base/mixins' as m;
#contenedor_contacto{
    padding-bottom: 2rem;
    background: rgb(39,60,117);
    background: linear-gradient(180deg, rgba(39,60,117,0) 50%, rgba(0,0,0,1) 100%);
    a{
        font-size: large;
        color: v.$silver ;
    }
    .card-title{
        color: v.$silver;
    }
    i{
        color: v.$amarillo;
    }
    @include m.tablet {
        .card{
            margin-left: auto;
            margin-right: auto;
            max-width: 70rem;
            p,a{
                font-size: x-large;
            }
        }
        .titulo-seccion {
            font-size: 5rem;
          }
        .card:hover{
            scale: 1.05;
        }
        .card{
            transition: 1s;
        }
        
    }
    .boton{
        width: 100%;
        padding-top: 2rem;
    }

    #irAInicio{
        background: v.$amarillo;
        padding: 1rem;
        border-radius: 1rem 0 1rem 0;
        color: v.$grisOscuro;
        box-shadow: 5px 5px 15px 0 #000;
        
    }
    #irAInicio:hover{
        color: v.$amarillo;
    background-color: v.$grisOscuro;
    
    scale: 1.1;
    
    }
    #irAInicio{
        transition: 1s;
    }
}