@use "base/variables" as v;
@use "base/mixins" as m;

#contenedor_servicios {
  align-items: center;
  border-bottom: 5px solid v.$amarillo;
  text-align: justify;
  background-image: url("../../../build/img/acero7.webp");
  background-size: cover;
  .titulo-seccion {
    color: v.$amarillo;
    border-radius: 3rem;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
  .card-title {
    font-size: 2rem;
  }

  .card:hover {
    background-color: v.$amarillo;
    border: 3px solid v.$blue-nights;
    scale: 1.05;

    .card-title {
      color: v.$grisOscuro;
    }
    .card-text {
      color: v.$grisOscuro;
      font-weight: 600;
    }
  }

  .card {
    background-color: v.$grisOscuro;
    transition: 1s;
  }
  .servicios {
    display: grid;
    grid-template-columns: 1fr;
  }

  @include m.tablet {
    .servicios {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr 1fr 1fr;
      .caja1 {
        grid-column: 2/4;
      }
      .caja2 {
        grid-row: 2/3;
        grid-column: 3/5;
      }
      .caja3 {
        grid-row: 3/4;
        grid-column: 4/6;
      }
    }
    .titulo-seccion {
      color: v.$amarillo;
      font-size: 5rem;
    }
    .card-title {
      font-size: 4rem;
    }
  }
}
