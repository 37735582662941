
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;800&display=swap"');


// Fuentes
$fuente_principal: 'Poppins', sans-serif;
$separacion: 2rem;
// Tamaño de Media Queries
$telefono: 480px;
$tablet: 768px;
$desktop: 1024px;

// Colores
$negro: #000000;
$amarillo: #ffcd11;
// $amarillo: #ff1c1c;
$verde: #38b100;
$blanco: #f8f9fa;
$gris: #858383;
$grisOscuro: #222;
$rojo: #ff1c1c;
$marino: #ff1c1c;


$blue-nights: #353b48;
$silver:#d9e0fe;

// Fuentes
$ultraDelgada:200;
$delgada: 300;
$regular : 400;
$bold: 600;
$black: 800
