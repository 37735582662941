
@use '../base/variables' as v;
@use '../base/mixins' as m;

#contenedor_header{
    background:v.$grisOscuro;
    position: absolute;
    z-index: 100;
    margin: 0;
    text-align: center;
    color: v.$amarillo;    
    
    a{
        color: v.$amarillo;
    }
    img{
        max-width: 25rem;
    }
    .nav-item:hover{
        scale: 1.05;
        border-radius: 0 5rem 5rem 0;
        background: linear-gradient(90deg, rgba(131,58,180,0) 0%, rgba(255, 255, 255,0.5)100%);
       
    }
    
    @include m.tablet {
      background: linear-gradient(90deg, rgba(131,58,180,0) 0%, rgba(0,0, 0,0.9) 100%);
        font-size: 2.5rem;
        .menu-r{
            display: none;
        }
    }

   
}



 