@use 'base/variables' as v;
@use 'base/mixins' as m;


#contenedor_somos{
    background-color: v.$blanco; 
    text-align: justify;
    padding: 3rem;
    border-top: 5px solid v.$amarillo;
    border-bottom: 5px solid v.$amarillo;
    overflow: hidden;
    p{
        font-size:small;
        color: v.$grisOscuro;
    }
    .titulo-seccion {
        color: v.$amarillo;
      }
    .somos_img{
        display: grid;
        grid-template-columns: 1fr;        
        gap: 2rem;
        img{
            border: 2px solid v.$amarillo;
            box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.58);
            border-radius: 3rem 0 3rem 0;  
            width: 100%;
            
        }
    }
    
    @include m.tablet {                 
        
        .historia{
            display: flex;
            justify-content: center;
    
        }
        .somos_img{
            display: flex;
            justify-content: center;

            picture{
                max-width: 40rem;
            }
            img{
                max-height: 40rem;

            }
        }
        p{
            margin: 2rem 0 2rem 0 ;
            font-size: 2.2rem;
            max-width: 120rem;
        }
        .titulo-seccion{
            color: v.$amarillo;
            font-size: 5rem;
        }
    }
    
    
}