@use 'base/variables' as v;
@use 'base/mixins' as m;
#contenedor_galeria--main{
    overflow: hidden;
    background: url(../../../build/img/wp1.webp);
    height: 100vh;
    background-size: cover;
    
    .marca{
        position: absolute;
        top: 10rem;
        z-index: 50;
        background-color: v.$grisOscuro;
        width: 100%;
        color: v.$silver;    
        opacity: .8;   
    }
    .titulo{
        color: v.$silver;
        width: 100%;
        position: absolute;
        bottom: 5rem;
        z-index: 50;
        background-color: v.$grisOscuro;
        opacity: .8;
        h1{
            margin: 0;
        }
        font-size: v.$black;
    }
    .color-a{
        color: v.$amarillo;
    }
    .color-b{
        color: v.$silver;
    }
    
    @include m.tablet {
        .marca{
            width: 60%;
            border-radius: 0 6rem 6rem 0;
            h2{
                font-size: 6rem;
            }
        }
        .titulo{
            width: 65%;
            right: 0;
            border-radius: 10rem 0 0 10rem;
            h1{
                font-size: 7rem;
            }
        }
    }
}
